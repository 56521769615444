.movies-list-page {
  flex: 1;
  padding-top: 65px;
  padding: 80px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.movies-list-content {
  flex: 1;
  padding: 0px 10px;
}
.uploadModal {
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(50px);
  justify-content: center;
  align-items: center;
}

.uploaderContainer {
  background-color: transparent;
  height: 400px;
  width: 350px;
}

.movie-card-header {
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  padding: 5px 15px 0px 15px;
  display: flex;
  min-height: 60px;
}
.movie-card-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
}

.divider {
  border-bottom: 1px solid rgba(249, 249, 249, 0.1);
  padding: 10px 0px;
}

.movie-card-actions {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.movie-card {
  background-color: var(--PrimaryColor);
  border-radius: 10px;
  min-height: 310px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding: 5px 0px 10px 0px;
  float: left;
}
.movie-action-container {
  padding: 0px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.move-card-thumbnail-container {
  display: flex;
  padding: 0px 15px;
}

.movie-card-thumbnail {
  height: 60px;
  width: 110px;
  border-radius: 10px;
}

.release-date-container {
  flex: 1;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.release-date-title {
  margin: 0px;
  font-size: 12px;
  font-weight: 300;
  color: white;
}
.release-date-text {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
  color: white;
}

.genre-title {
  margin: 0px;
  font-size: 7px;
  font-weight: 300;
  color: white;
}

.section-title {
  padding-left: 15px;
  margin: 0px;
  font-size: 7px;
  font-weight: 300;
  color: white;
}

.genre-text {
  margin: 0px;
  font-size: 7px;
  font-weight: 600;
  color: white;
  padding-top: 3px;
  padding-right: 3px;
  text-transform: uppercase;
}

.movie-genre-container {
  background-color: rgba(249, 249, 249, 0.1);
  margin-top: 10px;
  padding: 5px 15px;
}

.video-container {
  outline: none;
  flex: 1;
  height: 100%;
  width: 100%;
  position: relative;
}

.video-modal {
  flex: 1;
  display: flex;
  background-color: black;
  height: 100%;
  width: 100%;
}
.trailer {
  color: var(--PrimaryColor);
  font-size: 7px;
  font-weight: 600;
  cursor: pointer;
  margin: 0px;
  padding: 2px 3px;
  background-color: lightgray;
  border-radius: 8px;
  min-width: 25px;
  max-height: 20px;
  text-align: center;
}

.trailer:active {
  background-color: #ffffff;
}

.video-modal-loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex{
  display: flex;
}
