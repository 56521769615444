.countries-table-header {
  font-weight: bold;
}

.countries-table-pagination {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.loading-container {
  height: 80vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.countries-loader {
  color: black;
}

.country-name {
  font-weight: 600;
  color: black;
}

.selected-tab{
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0px 15px;
  background-color:#0097A7;
  border-radius:3px;
  color:white;
  cursor:pointer
}

.tab{
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  padding:0px 15px;
  cursor:pointer
}

.tab-container{
  width:100%;
  border-bottom:1px solid gray;
  display:flex;
  padding:10px
}

.tab-text{
  font-size:12px;
  font-weight:bold
}
