body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --PrimaryColor: #0097a7;
}

.dashboard-page {
  flex: 1;
  padding-top: 65px;
  padding: 80px 20px 20px 20px;
  display: flex;
  flex-direction: column;
}

.centered-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centered-loader-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.zero-margin {
  margin: 0px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-upload-modal {
  display: flex;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(7px);
  justify-content: center;
  align-items: center;
}

.cast-modal {
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  justify-content: center;
  align-items: center;
}

.flex-container {
  display: flex;
}

.form-error {
  color: #f00;
  padding-bottom: 10px;
}

.one-flex {
  flex: 1;
}

.flex-end {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.video-js .background{
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
}

.background>.vjs-loading-spinner{display:none!important;}

.background>.vjs-tech{
  width: 100% !important;
  height: 100% !important;
  object-fit: fill;
}

.fa-facebook {
  background: transparent;
  color: white;
}

.fa-twitter {
  background: transparent;
  color: white;
}

.fa-instagram {
  background: transparent;
  color: white;
}

.fa {
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  /* margin-top: 15px; */
  padding: 5px;
  font-family: Roboto, sans-serif;
}
/* .fa-facebook{
  padding: 5px 15px;
} */

.fa-facebook:hover {
  background-color: #3b5998;
  padding: 5px;
  border-radius: 6px;
}

.fa-twitter:hover {
  background-color: #55acee;
  padding: 5px;
  border-radius: 6px;
}

.fa-youtube-play {
  background: transparent;
  color: white;
}
.fa-youtube-play:hover {
  background-color:#ff0000;
  padding: 5px;
  border-radius: 6px;
}

.fa-instagram:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  padding: 5px;
  border-radius: 6px;
}
