/* Pricing card styling */
.pricing-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
}
.pricing-card-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  padding: 0px 15px 0px 15px;
}
.pricing-countries {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
}

.pricing-country {
  color: #ffffff;
  font-size: 12;
  font-weight: 400;
  padding: 0px 15px 0px 15px;
}

.pricing-type-container {
  background-color: rgba(249, 249, 249, 0.1);
  margin-top: 10px;
  padding: 5px 15px;
  display: flex;
  overflow: auto;
}

.pricing-detail {
  padding-right: 17px;
}

.pricing-detail-key {
  font-weight: 400;
  font-size: 9px;
  color: #ffffff;
  text-transform: uppercase;
}

.pricing-detail-value {
  font-weight: 700;
  font-size: 9px;
  color: #ffffff;
  padding-top: 5px;
}

.pricing-plan {
  background-color: var(--PrimaryColor);
  border-radius: 10px;
  /* height: 170px; */
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  cursor: pointer;
  padding: 15px 0px 10px 0px;
}

.faded-pricing-plan{
  background-color: 'gray';
  border-radius: 10px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  cursor: pointer;
  padding: 15px 0px 10px 0px;
}

.pricing-movie {
  background-color: var(--PrimaryColor);
  border-radius: 10px;
  height: 80px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  font-weight: 500;
  cursor: pointer;
}
.pricing-movie:active {
  background-color: #027380;
}

/* Create button component */
.pricing-create-button {
  border: 2px dotted var(--PrimaryColor);
  border-radius: 10px;
  height: 80px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--PrimaryColor);
  font-weight: 500;
  cursor: pointer;
}

.pricing-gateway-container {
  padding: 10px 15px;
}

.pricing-filter-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-card-image {
  height: 100%;
  width: 100px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  object-fit: cover;
}

.pricing-card-detail {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 10px;
  flex-direction: column;
}
