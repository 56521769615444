.promo-code-main-container {
  flex: 0.8;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.promo-code-footer {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.promo-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.promo-code-form-label {
  font-size: 14px;
  padding-bottom: 5px;
  color:grey
}

.promo-code-type-select {
  width: 300px;
}

.promo-code-form-detail {
  flex: 1;
  padding: 20px;
}

.promo-code-form-input {
  width: 300px;
  padding: 10px;
  float: left;
}

.dummy-container {
  width: 300px;
  height: 80px;
  padding: 10px;
  float: left;
}

.promo-detail-form-container {
  width: 600px;
}