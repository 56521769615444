.image-dropzone {
  width: 260px;
  color: gray;
  border: 1px dashed gray;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
}

.dropzone-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.dropzone-container {
  padding: 10px;
}

.image-container-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-image {
  height: 120px;
  width: 130px;
  border-radius: 5px;
}

.dropzone-action-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dropzone-progressbar {
  width: 260px;
  padding: 10px 0px;
  margin: 0px 0px;
}
