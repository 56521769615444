.create-pricing-container {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.create-pricing-form {
  width: 530px;
}
.create-pricing-input-container {
  width: 430px;
  padding-left: 50px;
}
.create-pricing-fetching-plans {
  width: 430px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gray;
}
.input-label {
  padding-right: 60px;
}

.create-pricing-input {
  /* width: 430px; */
  width: 100%;
  padding: 10px 0px;
}

.create-pricing-footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-pricing-footer-button {
  padding: 0px 10px;
}

.create-pricing-payment-container {
  width: '100%';
  padding: 10px 0px;
  display: flex;
}

.remove-plan-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.plan-title-input-container {
  width: 200px;
}

.plan-pricing-input-container {
  width: 120px;
  padding: 0px 10px;
}

.plan-country-code-input {
  width: 60px;
}

.plan-status-container {
  padding: 0px 15px;
}

.add-plan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.plans-table-container {
  outline: none;
  background-color: #ffffff;
  min-height: 400px;
  min-width: 600px;
  max-height: 500px;
  border-radius: 5px;
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.plans-table {
  flex: 1;
}
.plan-table-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}

.plan-row-check {
  flex: 0.12;
}
.plan-row-title {
  flex: 0.25;
  text-transform: uppercase;
  color: #000000;
  font-weight: 600;
}
.plan-row-quality {
  flex: 0.2;
}
.plan-row-devices {
  flex: 0.43;
}
.device-tag {
  margin: 0px 3px;
  font-size: 11px;
  border-radius: 8px;
  padding: 4px 6px;
  background-color: lightgray;
}

.plans-table-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.create-pricing-error{
  margin:0px;
    color:red;
    text-align: center;
}
