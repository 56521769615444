.cast-list-content {
  flex: 1;
  padding: 0px 10px;
}

.create-cast-button {
  border: 2px dotted var(--PrimaryColor);
  border-radius: 10px;
  height: 170px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--PrimaryColor);
  font-weight: 500;
  cursor: pointer;
}

.progress-container{
  flex:1;
  justify-content:center;
  align-items:center;
  display:flex;
  flex-direction:column;
  height:170px;
  width:200px
}

.create-cast-button:active {
  border: 2px dotted #006d79;
  color: #006d79;
}

.cast-card {
  background-color: var(--PrimaryColor);
  border-radius: 10px;
  height: 170px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  padding: 10px;
  float: left;
}

.cast-card-header {
  display: flex;
}

.cast-card-title {
  flex: 1;
}

.cast-card-nickname {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.cast-card-fullname {
  color: #ffffff;
  font-size: 15px;
}

.cast-image {
  height: 70px;
  width: 65px;
  border-radius: 10px;
}

.cast-card-description {
  color: #ffffff;
  font-size: 9px;
  flex: 1;
}
