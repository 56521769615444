.create-plan-container {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.input-label {
  padding-right: 60px;
}

.create-plan-input {
  width: 400px;
  padding: 10px 0px;
}

.update-plan-devices {
  width: 400px;
  padding: 10px 0px;
  display: flex;
}

.plan-input {
  width: 400px;
  padding-top: 10px;
  display: flex;
}

.device-tag {
  padding: 0px 5px;
}

.status-container {
  align-items: center;
  flex: 1;
  display: flex;
  font-size: 16px;
  font-weight: 500;
}

.create-plan-footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-plan-footer-button {
  padding: 0px 10px;
}

.errorText {
  margin: 0px;
  color: red;
  text-align: right;
}

.create-plan-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.plan-expiry-unit {
  color: gray;
  font-size: 12px;
  width: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px;
}

.plan-expiry-input {
  width: 80px;
}
