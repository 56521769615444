.create-movie-main-container {
  flex: 1;
}

.create-movie-footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.create-movie-steps-button {
  padding: 0px 10px;
}

.create-movie-step {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px 10px 0px;
}

.create-movie-form-item {
  width: 260px;
  padding: 10px 0px;
  margin: 0px 10px;
}

.create-movie-form-genre {
  width: 260px;
  padding: 10px 0px;
}
.create-movie-genre-container {
  display: flex;
  align-items: flex-start;
  width: 540px;
}

.create-movie-description-item {
  width: 540px;
  padding: 10px 0px;
  margin: 0px 10px;
}

.create-movie-progress-item {
  width: 260px;
  padding: 10px 0px;
  margin: 0px 0px;
}

.create-movie-thumbnail-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-movie-form-label {
  color: black;
  padding-bottom: 10px;
}
.time-sapartor-container {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: black;
}

.create-caption-button {
  display: flex;
  justify-content: flex-end;
  width: 650px;
}

.caption-form {
  width: 650px;
  display: flex;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0px 10px;
  height: 100px;
  align-items: center;
  margin: 10px;
}

.caption-form-input-container {
  flex: 1;
  display: flex;
  /* width: 100%; */
}

.caption-form-input {
  padding-right: 20px;
  flex: 1;
}

.caption-upload-button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.file-type {
  margin: 0px;
  flex: 1;
  color: black;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-actions {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thumbnail-img {
  height: 80px;
  width: 180px;
  border-radius: 5px;
}
.thumbnail-dropzone {
  width: 100%;
  color: gray;
  border: 1px dashed gray;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
}
.caption-dropzone {
  /* width: 100%; */
  color: gray;
  border: 1px dashed gray;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  /* height:20px */
}

.thumbnail-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.caption-modal-container {
  height: 400px;
  width: 300px;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
  padding: 10;
  display: flex;
  flex-direction: column;
}
.caption-modal-title {
  text-align: center;
}

.caption-languages {
  flex: 1;
  overflow:auto
}
.caption-modal-footer {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
}
.caption-modal-footer-button {
  padding: 0px 10px;
}

.caption-upload-progress {
  flex: 1;
  padding-right: 20px;
}
.chip-container {
  padding-right: 30px;
}

.movie-form {
  width: 650px;
  /* display: flex; */
  /* border: 1px solid gray; */
  border-radius: 5px;
  padding: 0px 10px;
  height: 100px;
  align-items: center;
  margin: 20px 10px 10px 10px;
}

.trailer-form {
  flex: 1;
  display: flex;
  border-radius: 5px;

  /* padding: 0px 10px; */
  /* height: 100px; */
  align-items: center;
  margin: 20px 10px 10px 10px;
}

.cast-crew-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-cast-conatiner {
  max-height: 500px;
  padding: 20;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}

.add-cast-modal-title {
  text-align: center;
}

.add-cast-item-container {
  padding-bottom: 20px;
}

.create-cast-stepthree-list {
  flex: 1;
  width: 100%;
  float: left;
}

.step-three-cast-type-header {
  display: flex;
}

.step-three-cast-type-header-title {
  font-size: 16px;
  font-weight: bold;
}
.step-three-cast-type-header-divider {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.cast-card-container {
  width: 250px;
  height: 50px;
  border: 1px solid lightgray;
  border-radius: 5px;
  float: left;
  margin: 10px;
  display: flex;
}

.cast-card-name {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.cast-card-toggle-container {
  display: flex;
  align-items: center;
}

.trailer-form-container {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0px;
  position: relative;
  width: 860px;
  padding: 20px 20px;
}

.form-title-input {
  flex: 1;
  display: flex;
  padding-right: 10px;
}

.trailer-drop-zone-container {
  display: flex;
  flex: 1;
  padding-left: 10px;
}

.trailer-name {
  color: gray;
  text-align: center;
  margin: 0px;
  font-size: 10px;
}

.trailer-container-is-main {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.trailer-container-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.empty-captions-container {
  padding: 40px;
  font-size: 15px;
  color: gray;
}

.highlighted-text {
  color: black;
  background-color: lightgray;
  padding: 3px 10px;
  border-radius: 5px;
}
.drop-zone-container {
  display: flex;
  padding-top: 15px;
}
