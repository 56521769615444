.countries-table-header {
    font-weight: bold;
  }
  
  .countries-table-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .loading-container {
    height: 80vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .countries-loader {
    color: black;
  }
  
  .country-name {
    font-weight: 600;
    color: black;
  }
  .promo-code-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 25px;
  }
  
  .list-header {
    height: 50px;
    display: flex;
  }
  
  .promo-code-search-container {
    width: 25%;
  }
  .promo-code-dropdown-filter {
    width: 25%;
    padding-left: 30px;
  }
  .add-promo-code-button {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-bottom: 10px
  }
  .promo-code-table {
    margin-top: 5px;
  }
  .promo-code-form-label {
    font-size: 14px;
    padding-bottom: 5px;
    color:grey
  }
  