.movie-pricing-page{
    flex: 1;
    display: flex;
    flex-direction: column;
}

.empty-container{
    display: flex;
    flex:1;
    justify-content:center;
    align-items: center;
    flex-direction: column;
}