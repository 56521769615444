.plan-filter-container {
  height: 50px;
  display: flex;
}
.search-container {
  width: 50%;
}
.dropdown-filter {
  width: 25%;
  padding-left: 30px;
}

.plan-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
}

.plans-list-container {
  flex: 1;
}

.add-icon {
  font-size: 17;
  margin-right: 5px;
}
.plan-list-filter {
  width: 100%;
}
