.countries-table-header {
    font-weight: bold;
  }
  
  .countries-table-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .loading-container {
    height: 80vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .countries-loader {
    color: black;
  }
  
  .country-name {
    font-weight: 600;
    color: black;
  }

  .add-order-button {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
    margin-bottom: 10px
  }
  .caption-modal-container {
    height: 400px;
    width: 300px;
    border: 1px solid black;
    border-radius: 5px;
    outline: none;
    padding: 10;
    display: flex;
    flex-direction: column;
  }
  .caption-modal-title {
    text-align: center;
  }
  
  .caption-languages {
    flex: 1;
    overflow:auto
  }
  .caption-modal-footer {
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;
  }
  .caption-modal-footer-button {
    padding: 0px 10px;
  }
  