.create-cast-name-container {
  display: flex;
  flex-direction: row;
}
.create-cast-nickname-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 540px;
}
.create-cast-nickname-input {
  width: 260px;
  padding: 10px 0px;
}

.creat-cast-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cast-thumbnail-image {
  height: 120px;
  width: 130px;
  border-radius: 5px;
}

.create-cast-footer {
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
}

.images-upload-form {
  display: flex;
  justify-content: center;
}
