.promo-code-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 25px;
}

.list-header {
  height: 50px;
  display: flex;
}

.promo-code-search-container {
  width: 25%;
}
.promo-code-dropdown-filter {
  width: 25%;
  padding-left: 30px;
}
.add-promo-code-button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.promo-code-table {
  margin-top: 5px;
}
