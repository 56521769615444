/* Header styling */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

/* Movie Plan */
.movie-plan {
  background-color: var(--PrimaryColor);
  border-radius: 10px;
  height: 190px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  /* cursor: pointer; */
  padding: 15px 0px 10px 0px;
}
.plan-actions {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.plan-action-container {
  padding: 0px 5px;
}

.plan-header {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  padding: 0px 15px;
  display: flex;
  text-transform: uppercase;
}

.plan-quality {
  color: #ffffff;
  font-size: 12px;
  font-weight: 300;
  padding: 0px 15px;
}

.plan-type-container {
  background-color: rgba(249, 249, 249, 0.1);
  margin-top: 10px;
  padding: 0px 15px;
  display: flex;
}

.plan-detail-item {
  padding-right: 10px;
}
.plan-type {
  color: #ffffff;
  font-size: 10px;
  font-weight: 200;
  padding-top: 5px;
  margin: 0px;
}

.plan-type-name {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  padding: 5px 0px;
  margin: 0px;
}

.plan-devices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0px 15px;
  margin-top: 10px;
}
.plan-device {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: #ffffff;
  font-weight: 600;
}

/* Create button component */
.create-button {
  border: 2px dotted var(--PrimaryColor);
  border-radius: 10px;
  height: 170px;
  width: 250px;
  float: left;
  margin: 10px 20px 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--PrimaryColor);
  font-weight: 500;
  cursor: pointer;
}

.create-button:active {
  border: 2px dotted #006d79;
  color: #006d79;
}

/* Country Container */
.country-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  padding: 0px 15px 20px 15px;
}

.country-type-container {
  background-color: rgba(249, 249, 249, 0.1);
  margin-top: 10px;
  padding: 5px 15px;
  display: flex;
}

.country-detail {
  padding-right: 17px;
}

.country-detail-key {
  font-weight: 400;
  font-size: 9px;
  color: #ffffff;
}

.country-detail-value {
  font-weight: 700;
  font-size: 9px;
  color: #ffffff;
  padding-top: 5px;
}

/* File drop zone */
.drop-zone-main-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  outline: none;
}

.drop-zone-container {
  height: 400px;
  width: 400px;
  border-radius: 15px;
  border: 2px dashed var(--PrimaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.outer-container {
  position: relative;
}

.close-button-container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
}

.or-text {
  margin: 0px;
  color: lightgray;
}
